import {
  AspectRatio,
  Box,
  Button,
  Image,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { useVideoSelector } from "context/video-selector.context";
import { useEffect } from "react";

const VideoSelector = ({ onSelect }) => {
  const { videos, load, loaded } = useVideoSelector();

  useEffect(() => {
    if (videos.length === 0) {
      load();
    }
  }, [videos, load]);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={1} my={3}>
        {videos?.map((video) => (
          <Box
            key={video.id}
            position="relative"
            role="button"
            onClick={() => onSelect(video)}
          >
            <AspectRatio ratio={9 / 16}>
              <Image src={video.thumbnail} />
            </AspectRatio>
            <Box
              position="absolute"
              bottom={0}
              py={1}
              px={2}
              fontWeight="bold"
              bg="rgba(0,0,0,.5)"
              width="100%"
            >
              {video.price} 鑽
            </Box>
          </Box>
        ))}
      </SimpleGrid>
      {!loaded && (
        <VStack pb={2} align="stretch" px={2}>
          <Button align="center" onClick={load}>
            讀取更多
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default VideoSelector;
