import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  ScaleFade,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import AddItem from "components/AddItem";
import FAIcon from "components/FAIcon";
import VideoSelector from "components/VideoSelector";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ref, uploadBytes } from "firebase/storage";
import { useFirebase } from "context/firebase.context";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Item = ({ type, picture, displayName, thumbnail, onRemove }) => (
  <Box mt={3}>
    {type === "creators" ? (
      <VStack>
        <Box position="relative">
          <Avatar src={getPublicDownloadUrl(picture)} size="xl" />
          <Circle
            size={5}
            position="absolute"
            bg="red.400"
            top={1}
            role="button"
            right={1}
            onClick={onRemove}
          >
            <FAIcon name="close" />
          </Circle>
        </Box>
        <Text align="center" mt={1}>
          {displayName}
        </Text>
      </VStack>
    ) : (
      <Box width={80} position="relative">
        <AspectRatio ratio={9 / 16} borderRadius="md">
          <Image src={thumbnail} />
        </AspectRatio>
        <Circle
          size={5}
          position="absolute"
          bg="red.400"
          top={-2}
          role="button"
          right={-2}
          onClick={onRemove}
        >
          <FAIcon name="close" />
        </Circle>
      </Box>
    )}
  </Box>
);

const CreatorSelector = ({ creators, onSelect }) => (
  <SimpleGrid columns={3} gap={2} p={8}>
    {creators.map((creator) => (
      <VStack
        role="button"
        key={`selector-${creator.id}`}
        mb={3}
        onClick={() => onSelect(creator)}
      >
        <Avatar src={getPublicDownloadUrl(creator.picture)} size="xl" />
        <Text fontSize="xs">{creator.displayName}</Text>
      </VStack>
    ))}
  </SimpleGrid>
);

const Group = ({
  name,
  type,
  items,
  onSort,
  onNameChange,
  onRemove,
  onMove,
  onAddItem,
  onRemoveItem,
}) => {
  const [buffer, setBuffer] = useState(name || "");
  const [priceAsc, setPriceAsc] = useState(false);
  const [timeAsc, setTimeAsc] = useState(false);

  const isCreator = type === "creators";

  const onSortPrice = useCallback(() => {
    const updated = items.sort((a, b) =>
      priceAsc ? a.price - b.price : b.price - a.price
    );
    onSort(updated);
    setPriceAsc((state) => !state);
  }, [items, priceAsc, onSort]);
  const onSortTime = useCallback(() => {
    const updated = items.sort((a, b) =>
      timeAsc ? a.createdAt - b.createdAt : b.createdAt - a.createdAt
    );
    onSort(updated);
    setTimeAsc((state) => !state);
  }, [items, timeAsc, onSort]);

  // debounce name edit
  useEffect(() => {
    if (name === buffer) return;
    const timeout = setTimeout(() => onNameChange(buffer), 500);
    return () => clearInterval(timeout);
  }, [buffer, name, onNameChange]);

  return (
    <Box key={name} mb={10} maxW={1200} overflow="auto">
      <Flex align="center" gap={4} mb={4}>
        <VStack>
          <FAIcon
            role="button"
            onClick={onMove(-1)}
            name="chevron-up"
            color="gray.400"
          />
          <FAIcon
            role="button"
            onClick={onMove(1)}
            name="chevron-down"
            color="gray.400"
          />
        </VStack>
        <Input
          value={buffer}
          onChange={(e) => setBuffer(e.target.value)}
          maxW={100}
          size="lg"
        />
        {type === "videos" && (
          <>
            <Button onClick={onSortPrice}>
              <Text>價格：</Text>
              <Text>{priceAsc ? "低 → 高" : "高 → 低"}</Text>
            </Button>
            <Button onClick={onSortTime}>
              <Text>時間：</Text>
              <Text>{timeAsc ? "舊 → 新" : "新 → 舊"}</Text>
            </Button>
          </>
        )}

        <FAIcon
          role="button"
          onClick={onRemove}
          name="trash-can"
          color="red.400"
          fontSize="xl"
        />
      </Flex>

      <Flex gap={4} align="center" overflowX="auto" width="100%">
        {items.map((item, index) => (
          <Item
            key={`${name}-${item.id}`}
            type={type}
            onRemove={onRemoveItem(index)}
            {...item}
          />
        ))}
        <AddItem
          onClick={onAddItem}
          ratio={isCreator ? null : 9 / 16}
          width={isCreator ? 28 : 80}
          height={isCreator ? 32 : null}
          minW={isCreator ? null : 80}
          rounded="lg"
        />
      </Flex>
    </Box>
  );
};

const AddBanner = ({ onAdd }) => {
  const fileRef = useRef(null);
  const [draft, setDraft] = useState(null);

  const createDraft = useCallback(() => setDraft({ url: "", file: null }), []);

  const updateDraftImage = useCallback((e) => {
    const attachment = e.target.files[0];
    setDraft((state) => ({ ...state, file: attachment }));
  }, []);

  const image = useMemo(() => {
    if (!draft?.file) return null;
    return URL.createObjectURL(draft.file);
  }, [draft]);

  const addBanner = useCallback(async () => {
    if (!draft || !draft.file) return;

    await onAdd(draft);
    setDraft(null);
  }, [draft, onAdd]);

  return (
    <>
      <Modal isOpen={!!draft} onClose={() => setDraft(null)}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {draft && (
              <Box p={6}>
                {image ? (
                  <Image src={image} mb={4} />
                ) : (
                  <Button onClick={() => fileRef.current.click()} mb={6}>
                    新增檔案
                  </Button>
                )}
                <Input
                  type="file"
                  accept="image/*"
                  ref={fileRef}
                  hidden
                  onChange={updateDraftImage}
                />
                <InputGroup>
                  <InputLeftAddon>網址</InputLeftAddon>
                  <Input
                    value={draft.url}
                    onChange={(e) =>
                      setDraft((state) => ({
                        ...state,
                        url: e.target.value,
                      }))
                    }
                  />
                </InputGroup>

                <Button
                  onClick={addBanner}
                  colorScheme="cyan"
                  width="100%"
                  mt={6}
                  isDisabled={!image}
                >
                  新增
                </Button>
              </Box>
            )}
          </ModalContent>
        </ModalBody>
      </Modal>

      <AddItem ratio={9 / 4} width="100%" onClick={createDraft} />
    </>
  );
};

export default function ExplorePage() {
  const { storage } = useFirebase();
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const api = useAPI();
  const [settings, setSettings] = useState(null);
  const [creators, setCreators] = useState(null);
  const [draftGroup, setDraftGroup] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [editing, setEditing] = useState(null);
  const toast = useToast();

  const editingGroup = useMemo(
    () => settings?.groups?.[editing] || {},
    [editing, settings?.groups]
  );

  const openGroupEditModal = useCallback(
    (index) => () => setEditing(index),
    []
  );
  const closeEditModal = useCallback(() => setEditing(null), []);

  const createDraftGroup = useCallback(
    () => setDraftGroup({ name: "", type: "creators", items: [], tags: [] }),
    []
  );

  const addGroup = useCallback(() => {
    setSettings((state) => ({
      ...state,
      groups: state.groups.concat([draftGroup]),
    }));
    setDraftGroup(null);
    setDirty(true);
  }, [draftGroup]);

  const moveGroup = useCallback(
    (index) => (vector) => () => {
      const clone = cloneDeep(settings);
      const target = index + vector;
      if (target < 0 || target >= clone.groups.length) return;
      const temp = clone.groups[index];
      clone.groups[index] = clone.groups[target];
      clone.groups[target] = temp;
      setSettings(clone);
      setDirty(true);
    },
    [settings]
  );

  const removeGroup = useCallback(
    (index) => () => {
      const clone = cloneDeep(settings);
      clone.groups.splice(index, 1);

      setSettings(clone);
      setDirty(true);
    },
    [settings]
  );
  const editGroupName = useCallback(
    (index) => (name) => {
      const clone = cloneDeep(settings);
      clone.groups[index].name = name;

      setSettings(clone);
      setDirty(true);
    },
    [settings]
  );
  const removeItem = useCallback(
    (groupIndex) => (itemIndex) => () => {
      const clone = cloneDeep(settings);
      clone.groups[groupIndex].items.splice(itemIndex, 1);

      setSettings(clone);
      setDirty(true);
      setEditing(null);
    },
    [settings]
  );
  const sortGroup = useCallback(
    (index) => (items) => {
      const clone = cloneDeep(settings);
      clone.groups[index].items = items;
      setSettings(clone);
      setDirty(true);
    },
    [settings]
  );

  const addItem = useCallback(
    async (newItem) => {
      const clone = cloneDeep(settings);
      clone.groups[editing].items.push(newItem);

      setSettings(clone);
      setDirty(true);
      setEditing(null);
    },
    [editing, settings]
  );

  const addBanner = useCallback(
    async (draft) => {
      const attachment = draft.file;
      const fileName = `explore-banner-${Date.now()}`;
      const image = `explore-banners/${fileName}.png`;
      const storageRef = ref(storage, image);
      await uploadBytes(storageRef, attachment);
      const banner = { image, url: draft.url || undefined };
      setSettings((state) => ({
        ...state,
        banners: state.banners.concat([banner]),
      }));
      setDirty(true);
    },
    [storage]
  );

  const removeBanner = useCallback(
    (index) => () => {
      const clone = cloneDeep(settings);
      clone.banners.splice(index, 1);
      setSettings(clone);
      setDirty(true);
    },
    [settings]
  );

  const saveSettings = useCallback(async () => {
    const serialized = { ...settings };
    serialized.groups = serialized.groups.map((group) => ({
      ...group,
      items: group.items.map((item) => item.id),
    }));
    setProcessing(true);
    await api.updateExploreSettings(serialized);
    setProcessing(false);
    toast({ title: "成功更新探索頁設定", status: "success" });
    setDirty(false);
  }, [api, settings, toast]);

  const loadExploreSettings = useCallback(() => {
    api.getExploreSettings().then(setSettings);
  }, [api]);

  useEffect(() => {
    loadExploreSettings();
  }, [loadExploreSettings]);

  useEffect(() => {
    api.getCreators().then((result) => setCreators(result?.data));
  }, [api]);

  return (
    <MainLayout p={3}>
      {settings == null && (
        <Center mt={6}>
          <CircularProgress isIndeterminate size={12} />
        </Center>
      )}

      {settings && (
        <Box maxW={1200} mx="auto">
          <Box mb={8} p={6}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              spaceBetween={30}
              loop
              modules={[Autoplay, Pagination, Navigation]}
            >
              {settings?.banners.map((banner, index) => (
                <SwiperSlide key={`banners-${index}`}>
                  <Box width="100%" position="relative">
                    <a
                      href={banner.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={getPublicDownloadUrl(
                          typeof banner === "string" ? banner : banner.image
                        )}
                        width="100%"
                      />
                    </a>

                    <Circle
                      role="button"
                      onClick={removeBanner(index)}
                      size={6}
                      position="absolute"
                      bg="red.400"
                      top={4}
                      right={4}
                    >
                      <FAIcon name="close" />
                    </Circle>
                  </Box>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                <AddBanner onAdd={addBanner} />
              </SwiperSlide>
            </Swiper>
          </Box>

          {settings.groups.map((group, index) => (
            <Group
              key={group.name}
              {...group}
              onNameChange={editGroupName(index)}
              onRemove={removeGroup(index)}
              onMove={moveGroup(index)}
              onRemoveItem={removeItem(index)}
              onAddItem={openGroupEditModal(index)}
              onSort={sortGroup(index)}
            />
          ))}
          <AddItem onClick={createDraftGroup} width={60} height={16} mt={12} />
        </Box>
      )}

      <Box
        position="fixed"
        bottom={{ base: 4, md: 8 }}
        right={{ base: 4, md: 8 }}
      >
        <ScaleFade in={dirty}>
          <Circle
            role="button"
            onClick={saveSettings}
            bg="red.500"
            size={{ base: 14, md: 20 }}
          >
            <VStack align="center" gap={1}>
              {processing ? (
                <Spinner />
              ) : (
                <FAIcon
                  name="floppy-disk"
                  fontSize={{ base: "xl", md: "2xl" }}
                />
              )}
              <Text color="white" fontSize="xs" fontWeight="bold">
                儲存
              </Text>
            </VStack>
          </Circle>
        </ScaleFade>
      </Box>

      <Modal isOpen={!!draftGroup} onClose={() => setDraftGroup(null)}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {draftGroup && (
              <Box p={6}>
                <Text fontSize="2xl" mb={6}>
                  新增探索群組
                </Text>
                <InputGroup>
                  <InputLeftAddon>名稱</InputLeftAddon>
                  <Input
                    value={draftGroup.name}
                    onChange={(e) =>
                      setDraftGroup((state) => ({
                        ...state,
                        name: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <RadioGroup
                  value={draftGroup.type}
                  mt={4}
                  onChange={(type) =>
                    setDraftGroup((state) => ({
                      ...state,
                      type,
                    }))
                  }
                >
                  <Flex gap={4}>
                    <Radio value="creators">創作者</Radio>
                    <Radio value="videos">影片</Radio>
                  </Flex>
                </RadioGroup>
                <Button
                  onClick={addGroup}
                  colorScheme="cyan"
                  width="100%"
                  mt={6}
                  isDisabled={!draftGroup.name}
                >
                  新增
                </Button>
              </Box>
            )}
          </ModalContent>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editing != null}
        onClose={closeEditModal}
        size={isDesktop ? "md" : "xs"}
      >
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {editingGroup.type === "creators" && (
              <CreatorSelector creators={creators} onSelect={addItem} />
            )}
            {editingGroup.type === "videos" && (
              <VideoSelector onSelect={addItem} />
            )}
          </ModalContent>
        </ModalBody>
      </Modal>
    </MainLayout>
  );
}
