import {
  Box,
  Button,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import dayjs from "dayjs";
import useAPI from "hooks/api";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";

export default function DepositSearchPage() {
  const api = useAPI();
  const inputRef = useRef(null);
  const toast = useToast();
  const [deposit, setDeposit] = useState(null);
  const search = useCallback(async () => {
    if (!inputRef.current) return;
    const depositId = inputRef.current.value.trim();
    if (!depositId) return;
    const response = await api.getDeposit(depositId);
    const { error = null } = response;
    if (!error) {
      setDeposit(response);
      return;
    }
    toast({
      title: "找不到指定儲值訂單",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }, [api, setDeposit, toast]);

  return (
    <MainLayout>
      <SimpleGrid spacing={2} paddingX={4} marginTop={4}>
        <FormControl>
          <Input placeholder="訂單 ID" ref={inputRef} />
          {/* <FormErrorMessage>We'll never share your email.</FormErrorMessage> */}
        </FormControl>
        <Button onClick={search} colorScheme={"blue"}>
          搜尋
        </Button>
        {deposit && (
          <Box padding={4} backgroundColor={"gray.700"} borderRadius={"md"}>
            <SimpleGrid spacing={4}>
              <Flex alignItems={"center"} columnGap={2}>
                <Circle size={8} backgroundColor={"black"}></Circle>
                <Text>使用者 ID: {_.get(deposit, "userId", "--")}</Text>
              </Flex>
              <FormControl>
                <FormLabel>訂單 ID</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "id")}
                />
              </FormControl>
              <FormControl>
                <FormLabel>儲值金額</FormLabel>
                <Input
                  type="number"
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "price", 0)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>獲得鑽石</FormLabel>
                <Input
                  type="number"
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "points", 0)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>儲值時間</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={dayjs
                    .unix(_.get(deposit, "createdAt", "") / 1000)
                    .format("YYYY MM DD HH:mm:ss (dddd)")
                    .toString()}
                />
              </FormControl>
              <FormControl>
                <FormLabel>發票號碼</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "invoice.code", 0)}
                />
              </FormControl>
            </SimpleGrid>
          </Box>
        )}
      </SimpleGrid>
    </MainLayout>
  );
}
